<template lang="pug">
.rooms
  .breadcrumbs
    h2 Tax Reporting
  .table
    b-card.adminCard
      b-button(:to="{name:'taxReporting'}" v-if='month').mb-2 Back to monthly view
      b-table(striped hover fixed :fields='fields' :items="taxes" :busy="loading")
        template(#cell(month)="data")
          router-link(v-if='!month' :to="{name: 'taxReporting', params: {month: data.item.month}}") {{ dateFormat(data.item.month) }}
          span(v-else) {{ dateFormat(data.item.month) }}
        template(#cell(giftcards)="data")
          span {{ data.item.giftcards | toCurrency }}
        template(#cell(roomAmount)="data")
          span {{ data.item.roomAmount | toCurrency }}
        template(#cell(extraWithGratuity)="data")
          span {{ data.item.extraWithGratuity | toCurrency }}
        template(#cell(extraWithoutGratuity)="data")
          span {{ data.item.extraWithoutGratuity | toCurrency }}
        template(#cell(gratuity)="data")
          span {{ data.item.extraWithGratuity * 0.18 | toCurrency }}
        template(#cell(taxCollected)="data")
          span {{ data.item.capturedTax | toCurrency }}
        template(#cell(refundedAmount)="data")
          span ({{ data.item.refundedAmount | toCurrency }})
        template(#cell(discounts)="data")
          span ({{ data.item.discounts | toCurrency }})
        template(#cell(gross)="data")
          span {{ calculatedGross(data.item) | toCurrency }}
        template(#cell(total)="data")
          span {{ total(data.item) | toCurrency }}
        template(v-slot:table-busy)
          .text-center.text-danger.my-2
            b-spinner.align-middle
              strong Loading...
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import {
  TaxesQuery
} from '@/generated/graphql'
import { sdk } from '@/graphql/client'
import { format, parse } from 'date-fns'
@Component({ components: {} })
export default class Admins extends Vue {
  @Prop() month!: string

  taxes: TaxesQuery['taxes'] = []
  fields = ['month',
    { key: 'giftcards', label: 'Gift Cards' },
    { key: 'roomAmount', label: 'Rooms' },
    { key: 'extraWithGratuity', label: 'Extra (Tipped)' },
    { key: 'extraWithoutGratuity', label: 'Extra (Not Tipped)' },
    { key: 'gratuity', label: 'Tips' },
    { key: 'taxCollected', label: 'TaxCollected' },
    { key: 'refundedAmount', label: 'Refunds' },
    { key: 'discounts', label: 'Discounts' },
    { key: 'gross', label: 'Gross' },
    { key: 'total', label: 'Total' }
  ]

  loading = false
  mounted () {
    this.loadTaxes()
  }

  calculatedGross (taxRow): number {
    return taxRow.giftcards +
      taxRow.roomAmount +
      taxRow.extraWithGratuity +
      taxRow.extraWithoutGratuity +
      taxRow.extraWithGratuity * 0.18 +
      taxRow.capturedTax -
      taxRow.discounts
  }

  gross (taxRow): number {
    return taxRow?.giftcards + taxRow.capturedAmount
  }

  total (taxRow): number {
    return this.calculatedGross(taxRow) - taxRow.refundedAmount
  }

  dateFormat (string) {
    if (this.month) {
      return format(parse(string, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy')
    } else {
      return format(parse(string, 'yyyy-MM-dd', new Date()), 'MMM yyyy')
    }
  }

  async loadTaxes () {
    this.loading = true
    const taxes = await sdk.Taxes({ month: this.month })
    this.taxes = taxes.taxes
    this.loading = false
  }

  @Watch('month')
  onMonthChange () {
    this.loadTaxes()
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 2rem;
}
.actions {
  margin-bottom: 20px;
  text-align: right;
}
.link {
  cursor: pointer;
  color: #007bff;
  &:hover {
    color: #0056b3;
  }
}

.avatar {
  max-width: 100px;
}
</style>
