<template lang="pug">
  .rooms(v-if='room')
    .breadcrumbs
      h2 Edit {{ room.title }}
    RoomForm(:saving='saving' :room='room' @save='save')
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import RoomGQL from '@/graphql/queries/room.graphql'
import EditRoomGQL from '@/graphql/mutations/edit_room.graphql'
import Client from '@/graphql/client'
import swal from 'sweetalert2'
import {
  RoomQuery,
  RoomQueryVariables,
  Maybe,
  EditRoomMutation,
  EditRoomMutationVariables
} from '@/generated/graphql'
import RoomForm from './Form.vue'
@Component({ components: { RoomForm } })
export default class EditRoom extends Vue {
  saving = false
  room: Maybe<RoomQuery['room']> = null

  @Prop({ required: true }) id!: string

  async mounted () {
    this.room = (
      await Client.query<RoomQuery, RoomQueryVariables>(RoomGQL, {
        id: parseInt(this.id)
      })
    ).room
  }

  async save (payload) {
    this.saving = true
    await Client.query<EditRoomMutation, EditRoomMutationVariables>(
      EditRoomGQL,
      { id: parseInt(this.id), room: payload }
    )
    this.saving = false
    swal.fire('Awesome!', `${this.room!.title} has been updated!`, 'success')
    this.$router.push({
      name: 'rooms'
    })
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 2rem;
}
.actions {
  margin-bottom: 20px;
  text-align: right;
}
</style>
