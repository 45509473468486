/* eslint-disable @typescript-eslint/no-unused-vars */
import Dialog from '@/lib/dialog'

const DialogPlugin = {
  install (Vue) {
    Vue.mixin({
      created () {
        this.$confirm = async (key: string, _args = {}, opts = {}): Promise<boolean> => {
          return new Dialog(this).confirm(key, opts)
        }
        this.$alert = async (key: string, _args = {}): Promise<boolean> => {
          return new Dialog(this).alert(key)
        }
      }
    })
  }
}

export default DialogPlugin
