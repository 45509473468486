<template lang="pug">
  .rooms
    .breadcrumbs
      h2 Create a Extra
    ExtraForm(:saving='saving' @save='save')
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import createExtraGQL from '@/graphql/mutations/create_extra.graphql'
import Client from '@/graphql/client'
import {
  CreateExtraMutation,
  CreateExtraMutationVariables
} from '@/generated/graphql'
import ExtraForm from './Form.vue'
@Component({ components: { ExtraForm } })
export default class CreateExtra extends Vue {
  saving = false

  async save (payload) {
    this.saving = true
    await Client.query<CreateExtraMutation, CreateExtraMutationVariables>(
      createExtraGQL,
      {
        extra: payload
      }
    )
    this.saving = false
    this.$router.push({ name: 'extras' })
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 2rem;
}
.actions {
  margin-bottom: 20px;
  text-align: right;
}
</style>

<style lang="scss">
.filepond--item {
  width: 50%;
}
</style>
