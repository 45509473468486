<template lang="pug">
  .rooms
    .breadcrumbs
      h2 Manage Giftcards
    .table
      b-card.adminCard
        .actions
          b-button(size='sm' variant='primary' :to="{name: 'createGiftcard'}")
            b-icon-plus-square-fill.mr-2
            span Create
        b-row
          b-col(cols='12')
            h4 Filter by
          b-col
            b-form-group(label='Code')
              b-form-input(size='sm' v-model='filters.code')
          b-col
            b-form-group(label='Recipient')
              b-form-input(size='sm' v-model='filters.recipient')
          b-col
            b-form-group(label='Sender')
              b-form-input(size='sm' v-model='filters.senderName')
        b-table(striped hover fixed :fields='fields' :items="extras" :busy="loading")
          template(v-slot:table-busy)
            .text-center.text-danger.my-2
              b-spinner.align-middle
                strong Loading...
          template(v-slot:cell(amount)="data")
            span ${{data.item.amount}}
          template(v-slot:cell(left)="data")
            span ${{data.item.left}}
          template(v-slot:cell(actions)="data")
            router-link(:to="{name: 'editGiftcard', params: {id: data.item.id}}" title='Edit')
              b-icon-pencil.mr-2
            a(href='#' title='Resend the giftcard' @click='resend(data.item.id)')
              b-icon-envelope.mr-2
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import giftcardsGQL from '@/graphql/queries/giftcards.graphql'
import giftcardResendGQL from '@/graphql/mutations/giftcard_resend.graphql'
import Client from '@/graphql/client'
import swal from 'sweetalert2'
import {
  GiftcardsQuery,
  GiftcardsQueryVariables,
  GiftcardResendMutation,
  GiftcardResendMutationVariables
} from '@/generated/graphql'
@Component({ components: {} })
export default class Rooms extends Vue {
  extras: GiftcardsQuery['giftcards'] = []
  fields = ['senderName', 'recipient', 'amount', 'left', 'actions']

  filters = {
    code: '',
    senderName: '',
    recipient: ''
  }

  loading = false
  mounted () {
    this.loadGiftcards()
  }

  async loadGiftcards () {
    this.loading = true
    const response = await Client.query<GiftcardsQuery, GiftcardsQueryVariables>(
      giftcardsGQL, { ...this.filters }
    )
    this.extras = response.giftcards
    this.loading = false
  }

  async resend (id) {
    const response = await Client.query<GiftcardResendMutation, GiftcardResendMutationVariables>(giftcardResendGQL, { id })
    if (!response) return
    swal.fire(
      'Gift card Sent!',
      `The gift card was sent successfully to ${response.giftcardResend!.recipient}`,
      'success'
    )
  }

  @Watch('filters', { deep: true })
  onFilterChange () {
    this.loadGiftcards()
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 2rem;
}
.actions {
  margin-bottom: 20px;
  text-align: right;
}
.link {
  cursor: pointer;
  color: #007bff;
  &:hover {
    color: #0056b3;
  }
}

.avatar {
  max-width: 100px;
}
</style>
