<template lang="pug">
.rooms
  .breadcrumbs
    h2 Manage admins
  .table
    b-card.adminCard
      .actions
        b-button(size='sm' variant='primary' :to="{name: 'createAdmin'}")
          b-icon-plus-square-fill.mr-2
          span Create
      b-table(striped hover fixed :fields='fields' :items="admins" :busy="loading")
        template(v-slot:table-busy)
          .text-center.text-danger.my-2
            b-spinner.align-middle
              strong Loading...
        template(v-slot:cell(role)="data")
          b-badge(:variant='variantForRole(data.item.role)') {{ data.item.role }}
        template(v-slot:cell(actions)="data")
          router-link(:to="{name: 'editAdmin', params: {id: data.item.id}}" title='Edit')
            b-icon-pencil.mr-2
          a.link(@click='deleteAdmin(data.item.id)')
            b-icon-trash
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import swal from 'sweetalert2'
import {
  AdminsQuery,
  Role
} from '@/generated/graphql'
import { sdk } from '@/graphql/client'
@Component({ components: {} })
export default class Admins extends Vue {
  admins: AdminsQuery['admins'] = []
  fields = ['email', 'role', 'actions']

  loading = false
  mounted () {
    this.loadAdmins()
  }

  async loadAdmins () {
    this.loading = true
    const response = await sdk.Admins()
    this.admins = response.admins
    this.loading = false
  }

  async deleteAdmin (adminId: number) {
    const confirm = await swal.fire({
      title: 'Are you sure?',
      confirmButtonText: 'Yes, delete it!',
      text: 'Once deleted, you will not be able to recover this user.',
      icon: 'warning'
    })
    if (confirm.isDismissed) { return }
    this.loading = true
    await sdk.DeleteAdmin({ id: adminId })
    await this.loadAdmins()
  }

  variantForRole (role: Role) {
    switch (role) {
      case Role.Admin:
        return 'success'
      case Role.Manager:
        return 'primary'
      case Role.User:
        return 'warning'
    }
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 2rem;
}
.actions {
  margin-bottom: 20px;
  text-align: right;
}
.link {
  cursor: pointer;
  color: #007bff;
  &:hover {
    color: #0056b3;
  }
}

.avatar {
  max-width: 100px;
}
</style>
