import * as ct from 'countries-and-timezones'
import { format, parseISO } from 'date-fns'

export const toGQLDate = (date: Date): string => {
  return format(date, 'yyyy-MM-dd')
}

export const timeDateJoin = (time, date) => {
  const timezone = ct.getTimezone('America/New_York')
  let dateString = format(date, 'yyyy-MM-dd')
  dateString += `T${time}.000${timezone.utcOffsetStr}`
  return parseISO(dateString)
}

export function parseTimeToDate (value: string) {
  const date = new Date()
  const [hours, minutes, _tail] = value.split(':').map(i => parseInt(i))
  date.setHours(hours)
  date.setMinutes(minutes)
  return date
}

export function conflict (ranges: { startA: string, endA: string, startB: string, endB: string }) {
  Object.entries(ranges).forEach(([k, v]) =>
    (ranges[k] = parseTimeToFloat(v)))

  const { startA, endA, startB, endB } = ranges

  const conflictStart = startA >= startB && startA < endB
  const conflictEnd = endA >= startB && endA < endB
  return conflictStart || conflictEnd
}

function parseTimeToFloat (val) {
  const [hour, sec, _] = val.split(':')
  return parseFloat(`${hour}.${sec}`)
}
