<template lang="pug">
  .extras(v-if='giftcard')
    .breadcrumbs
      h2 Edit
    GiftcardForm(:saving='saving' @save='save' :giftcard='giftcard' :error='error' @resetError='error=null')
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import updateGQL from '@/graphql/mutations/giftcard_update.graphql'
import giftcardGQL from '@/graphql/queries/giftcard_admin.graphql'
import Client from '@/graphql/client'
import {
  Maybe,
  GiftcardShowQuery,
  GiftcardShowQueryVariables,
  GiftcardUpdateMutation,
  GiftcardUpdateMutationVariables
} from '@/generated/graphql'
import GiftcardForm from './Form.vue'
@Component({ components: { GiftcardForm } })
export default class EditExtra extends Vue {
  @Prop() id!: string
  saving = false
  giftcard: Maybe<GiftcardShowQuery['giftcard']> = null
  error: Maybe<string> = null

  async mounted () {
    this.giftcard = (
      await Client.query<GiftcardShowQuery, GiftcardShowQueryVariables>(giftcardGQL, {
        id: parseInt(this.id)
      })
    ).giftcard
  }

  async save (payload) {
    this.saving = true
    try {
      await Client.query<GiftcardUpdateMutation, GiftcardUpdateMutationVariables>(
        updateGQL,
        {
          id: parseInt(this.id),
          input: payload.form,
          pin: parseInt(payload.pin)
        }
      )
      this.$router.push({ name: 'giftcards' })
    } catch (e: any) {
      if (e.message.includes('Not Authorized')) {
        this.error = e.message
      }
    }
    this.saving = false
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 2rem;
}
.actions {
  margin-bottom: 20px;
  text-align: right;
}
</style>

<style lang="scss">
.filepond--item {
  width: 50%;
}
</style>
