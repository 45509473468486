<template lang="pug">
b-modal(id="EditSlot" :title='EditModalTitle' @ok='editEvent' @show='setup' @cancel='cancel')
  b-form(v-if='event')
    b-form-group(label='Price')
      number-input(v-model='priceBuffer' controls center rounded :formatter-fn="priceFormatter")
  template(v-slot:modal-footer="{ ok, cancel, hide }")
    b-button(size="sm" variant="danger" @click="remove()" v-if='persisted').mr-auto Delete Slot
    b-button(size="sm" variant="secondary" @click="cancel()") Cancel
    b-button(size="sm" variant="success" @click="ok()") OK
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { RecurringEvent } from '@/types'
import { parseTimeToDate } from '@/lib/utils'
import { format } from 'date-fns'

@Component({ components: {} })
export default class SlotModal extends Vue {
  @Prop() event!: RecurringEvent
  priceBuffer = 0

  setup () {
    this.priceBuffer = this.event.extendedProps?.price
  }

  priceFormatter (value) {
    return `$${value}`
  }

  get EditModalTitle () {
    if (!this.event) return ''
    const startTime = format(parseTimeToDate(this.event.startTime), 'h:mm a')
    const endTime = format(parseTimeToDate(this.event.endTime), 'h:mm a')
    return `${startTime} - ${endTime}`
  }

  get persisted () {
    if (!this.event) return false
    return this.event.extendedProps?.new !== true
  }

  editEvent () {
    this.$emit('edit', Object.assign({}, this.event, { title: `$${this.priceBuffer}`, extendedProps: { price: this.priceBuffer } }) as RecurringEvent)
  }

  remove (): void {
    if (!this.event) return
    this.$emit('remove', this.event.id)
  }

  cancel (): void {
    if (this.event.extendedProps.new) this.$emit('remove', this.event.id)
  }

  @Watch('event')
  onEventChange () {
    (this as any).$bvModal.show('EditSlot')
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 2rem;
}
ul.slots {
  list-style: none;
  margin: 0;
  padding: 0;
}
.calendar {
  height: 80vh;
}
</style>
