<template lang="pug">
.extras(v-if='admin')
  .breadcrumbs
    h2 Edit
  AdminForm(:saving='saving' @save='save' :admin='admin')
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { sdk } from '@/graphql/client'
import {
  AdminCreateInput,
  AdminQuery,
  Maybe
} from '@/generated/graphql'
import AdminForm from './Form.vue'
@Component({ components: { AdminForm } })
export default class EditAdmin extends Vue {
  @Prop() id!: string
  saving = false
  admin: Maybe<AdminQuery['admin']> = null

  async mounted () {
    this.admin = (await sdk.Admin({ id: Number(this.id) })).admin
  }

  async save (payload: AdminCreateInput) {
    this.saving = true
    await sdk.UpdateAdmin({ id: Number(this.id), admin: payload })
    this.saving = false
    this.$router.push({ name: 'admins' })
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 2rem;
}
.actions {
  margin-bottom: 20px;
  text-align: right;
}
</style>

<style lang="scss">
.filepond--item {
  width: 50%;
}
</style>
