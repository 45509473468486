<template lang="pug">
  .rooms
    .breadcrumbs
      h2 Manage Extras
    .table
      b-card.adminCard
        .actions
          b-button(size='sm' variant='primary' :to="{name: 'createExtra'}")
            b-icon-plus-square-fill.mr-2
            span Create
        b-table(striped hover fixed :fields='fields' :items="extras" :busy="loading")
          template(v-slot:table-busy)
            .text-center.text-danger.my-2
              b-spinner.align-middle
                strong Loading...
          template(v-slot:cell(image)="data")
            b-img.avatar(:src='image(data.item.image)' fluid)
          template(v-slot:cell(type)="data")
            b-badge(:variant='variantForType(data.item.type)') {{ data.item.type }}
          template(v-slot:cell(position)="data")
            b-input.position(v-model='data.item.position' @update='move(data.item.id, $event)')
          template(v-slot:cell(actions)="data")
            router-link(:to="{name: 'editExtra', params: {id: data.item.id}}")
              b-icon-pencil.mr-2
            b-icon-trash.link(@click='deleteExtra(data.item.id)')
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import extrasGQL from '@/graphql/queries/extras.graphql'
import DeleteExtraGQL from '@/graphql/mutations/delete_extra.graphql'
import Client, { sdk } from '@/graphql/client'
import max from 'lodash/max'
import swal from 'sweetalert2'
import {
  ExtrasQuery,
  ExtrasQueryVariables,
  DeleteExtraMutation,
  DeleteExtraMutationVariables,
  ExtraType
} from '@/generated/graphql'
@Component({ components: {} })
export default class Rooms extends Vue {
  extras: ExtrasQuery['extras'] = []
  fields = ['image', 'title', 'price', 'position', 'type', 'actions']
  loading = false
  mounted () {
    this.loadExtras()
  }

  get maxPosition (): number {
    return max(this.extras.map((extra) => extra.position))
  }

  async move (id: number, position: string) {
    await sdk.moveExtra({ id, position: Number(position) })
  }

  async loadExtras () {
    this.loading = true
    const response = await Client.query<ExtrasQuery, ExtrasQueryVariables>(
      extrasGQL
    )
    this.extras = response.extras
    this.loading = false
  }

  async deleteExtra (id) {
    const confirm = await swal.fire({
      title: 'Are you sure?',
      confirmButtonText: 'Yes, delete it!',
      text: 'Once deleted, you will not be able to recover this extra',
      icon: 'warning',
      showCancelButton: true
    })
    if (confirm.isDismissed) { return }
    this.loading = true
    await Client.query<DeleteExtraMutation, DeleteExtraMutationVariables>(
      DeleteExtraGQL,
      { extra: id }
    )
    await this.loadExtras()
  }

  image (image) {
    return image?.variants?.thumb || image?.path
  }

  variantForType (type: ExtraType) {
    switch (type) {
      case ExtraType.Food:
        return 'success'
      case ExtraType.Alcohol:
        return 'primary'
      case ExtraType.Nonalcohol:
        return 'secondary'
      case ExtraType.Goodies:
        return 'warning'
    }
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 2rem;
}
.actions {
  margin-bottom: 20px;
  text-align: right;
}
.link {
  cursor: pointer;
  color: #007bff;
  &:hover {
    color: #0056b3;
  }
}

.position {
  width: 60px;
}

.avatar {
  max-width: 100px;
}
</style>
