<template lang="pug">
.room(v-if='room')
  .breadcrumbs
    h2 Manage Timeslots for {{ room.title }}
  SlotEditModal(:event='editedEvent' @add='newEvent' @remove='removeEvent' @edit='editEvent')
  b-card.adminCard
    .calendarContainer(ref='calendar')
    b-row
      b-col.text-right
        b-button(@click='save' :disabled='saving').mt-3
          b-spinner(small v-if='saving').mr-2
          span Save
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Client from '@/graphql/client'
import SlotsGQL from '@/graphql/queries/slots.graphql'
import UpdateSlotsGQL from '@/graphql/mutations/update_slots.graphql'
import SlotEditModal from './SlotEdit.vue'
import swal from 'sweetalert2'
import {
  RoomSlotsQuery,
  RoomSlotsQueryVariables,
  UpdateSlotsMutation,
  UpdateSlotsMutationVariables,
  Maybe
} from '@/generated/graphql'
import { Calendar } from '@fullcalendar/core'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import { v4 } from 'uuid'
import { RecurringEvent } from '@/types'
import { format, parse } from 'date-fns'
@Component({ components: { SlotEditModal } })
export default class RoomSlots extends Vue {
  @Prop({ required: true }) id!: string
  room: RoomSlotsQuery['room'] = null
  calendar: Calendar | null = null
  saving = false
  editedEvent: Maybe<RecurringEvent> = null

  currentEvents: RecurringEvent[] = []

  events (_info, success) {
    return success(this.currentEvents)
  }

  loadEvents () {
    if (!this.room) return
    this.currentEvents = this.room.timeSlots.map(slot => {
      return {
        id: slot!.id.toString(),
        daysOfWeek: [slot!.dayOfWeek],
        startTime: slot!.startTime,
        endTime: slot!.endTime,
        title: `$${slot!.price}`,
        extendedProps: {
          price: slot!.price,
          new: false
        }
      }
    })
  }

  newEvent (event: RecurringEvent) {
    this.currentEvents.push(event)
  }

  removeEvent (eventId: string) {
    this.currentEvents = this.currentEvents.filter((event) => event.id !== eventId)
  }

  editEvent (updatedEvent: RecurringEvent) {
    this.currentEvents = this.currentEvents.map((event) => {
      if (event.id === updatedEvent.id) {
        return updatedEvent
      } else {
        return event
      }
    })
  }

  async mounted () {
    this.room = (
      await Client.query<RoomSlotsQuery, RoomSlotsQueryVariables>(SlotsGQL, {
        id: parseInt(this.id)
      })
    ).room
    this.loadEvents()
    this.setupCalendar()
  }

  setupCalendar () {
    this.$nextTick(() => {
      this.calendar = new Calendar(this.$refs.calendar as HTMLElement, {
        plugins: [timeGridPlugin, interactionPlugin],
        height: 'calc(100vh - 240px)',
        timeZone: 'UTC',
        initialView: 'timeGridWeek',
        dayHeaderFormat: { weekday: 'short' },
        editable: true,
        eventClick: info => {
          this.editedEvent = this.currentEvents.find((event) => event.id === info.event.id)!
        },
        selectAllow: _info => {
          return true
        },
        eventResize: info => {
          if (!info.event.start || !info.event.end) return
          const editedEvent = {
            id: info.event.id,
            title: info.event.title,
            daysOfWeek: [this.dayOfWeek(info.event.startStr)],
            startTime: this.formatSlotTime(info.event.startStr),
            endTime: this.formatSlotTime(info.event.endStr),
            extendedProps: {
              price: info.event.extendedProps.price as number,
              new: false
            }
          }
          this.editEvent(editedEvent)
        },
        eventDrop: info => {
          if (!info.event.start || !info.event.end) return
          const editedEvent = {
            id: info.event.id,
            title: info.event.title,
            daysOfWeek: [this.dayOfWeek(info.event.startStr)],
            startTime: this.formatSlotTime(info.event.startStr),
            endTime: this.formatSlotTime(info.event.endStr),
            extendedProps: {
              price: info.event.extendedProps.price as number,
              new: false
            }
          }
          this.editEvent(editedEvent)
        },
        select: info => {
          if (!this.calendar) return
          const daysOfWeek = [this.dayOfWeek(info.startStr)]
          const newEvent = {
            id: v4(),
            title: '$50',
            daysOfWeek,
            startTime: this.formatSlotTime(info.startStr),
            endTime: this.formatSlotTime(info.endStr),
            extendedProps: {
              new: true,
              price: 50
            }
          }
          this.currentEvents.push(newEvent)
          this.editedEvent = newEvent
        },
        headerToolbar: {
          start: '',
          center: '',
          end: ''
        },
        events: this.events,
        views: {
          timeGrid: {
            timeZone: 'UTC',
            allDaySlot: false,
            selectable: true,
            selectMirror: true,
            selectOverlap: false,
            slotMinTime: '10:00:00',
            slotMaxTime: '29:00:00',
            slotDuration: '0:05:00'
          }
        }
      })
      this.calendar.render()
    })
  }

  dayOfWeek (UTCDate: string): number {
    const dateParts = UTCDate.split('T')
    const timeParts = dateParts[1].split(':')
    const hour = Number(timeParts[0])
    const date = parse(dateParts[0], 'yyyy-MM-dd', new Date())
    let dayofWeek = Number(format(date, 'i'))
    if (hour >= 0 && hour < 5) dayofWeek--
    if (dayofWeek === 7) dayofWeek = 0
    return dayofWeek
  }

  formatSlotTime (UTCDate: string) {
    const dateParts = UTCDate.split('T')
    const timeParts = dateParts[1].split(':')
    const hour = Number(timeParts[0])
    const min = timeParts[1]
    const isExtendedHoursStart = hour >= 0 && hour < 5 // midnight to 5am
    return isExtendedHoursStart ? `${hour + 24}:${min}:00` : `${hour}:${min}:00`
  }

  async save () {
    if (!this.room || !this.calendar) return
    this.saving = true
    await Client.query<UpdateSlotsMutation, UpdateSlotsMutationVariables>(
      UpdateSlotsGQL,
      {
        room: parseInt(this.id),
        slots: this.currentEvents.map(event => {
          return {
            dayOfWeek: event.daysOfWeek[0],
            startTime: event.startTime,
            endTime: event.endTime,
            price: event.extendedProps?.price
          }
        })
      }
    )
    this.saving = false
    this.$router.push({ name: 'rooms' })
    swal.fire(
      'Awesome!',
      `The timeslots for ${this.room.title} have been updated`,
      'success'
    )
  }

  @Watch('currentEvents')
  onChange (): void {
    if (!this.calendar) return
    this.calendar.refetchEvents()
    ;(this as any).$bvModal.hide('EditSlot')
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 2rem;
}
ul.slots {
  list-style: none;
  margin: 0;
  padding: 0;
}
</style>
