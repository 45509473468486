<template lang="pug">
div
  b-modal(id='viewReservation' title='Reservation Details' ref='modal' :no-enforce-focus='true' size="lg")
    .content(v-if='reservation')
      b-tabs(content-class="mt-3" justified)
        b-tab(title="Client Details")
          .paragraph
            h5 Name
            span {{ reservation.client.name }}
          .paragraph
            h5 Party
            span {{ reservation.partyName }}
          .paragraph
            h5 Email
            span {{ reservation.client.email }}
          .paragraph
            h5 Phone
            span {{ reservation.phoneNumber }}
          .paragraph(v-if='hasNotes')
            h5 Notes
            span {{ reservation.notes }}
          .paragraph
            h5 Private Notes
            b-form-row
              b-form-textarea(v-model='reservation.adminNotes')
          .paragraph
            h5 Assigned Waiter
            v-select(:options='waiterOptions' :value='waiter' @input='setWaiter')
        b-tab(title='Order Details')
          div(v-for='(slots, room) in rooms').mb-3
            h5 {{ room }}
            ul
              li(v-for='slot in slots')
                span {{ slot.startTime | timeFormat }} - {{ slot.endTime | timeFormat }}
          div(v-if='extras && extras.length')
            h5 Extras
            ul
              li(v-for='extra in extras')
                span {{ extra.title }} x {{ extra.quantity }}
        b-tab(title='Receipt Details')
          div.mb-3
            b-table(:items='lineItems' :fields='fields' striped hover small)
              template(#cell(baseAmount)="data")
                span {{ data.item.baseAmount / 100 | toCurrency }}
              template(#cell(taxAmount)="data")
                span {{ data.item.taxAmount / 100 | toCurrency }}
              template(#cell(discountAmount)="data")
                span {{ data.item.discountAmount / 100 | toCurrency }}
              template(#cell(total)="data")
                span {{ data.item.total / 100 | toCurrency }}
              template(#cell(refundedDate)="data")
                span {{ data.item.refundedDate | dateFormat }}
          hr
    template(v-slot:modal-footer)
      b-button(variant='info' @click='rebook').mr-auto Rebook
      b-button(variant='danger' :to="{name: 'manage', params: { id: reservation.stripeReference }, query: {lastMinute: true}}"  target="_blank") Cancel Reservation
      b-button(variant='primary' @click='close') Close
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { BookedReservationsQuery, ReservationTimeSlot, WaitersQuery, Role, ReservationRefundMutationVariables } from '@/generated/graphql'
import groupBy from 'lodash/groupBy'
import { EventClickArg } from '@fullcalendar/core'
import { sdk } from '@/graphql/client'
import { format } from 'date-fns'

type ReservationEvent = EventClickArg['event'] & { extendedProps: { reservation: BookedReservationsQuery['reservations'][0] } }

@Component({ components: {} })
export default class ReservationModal extends Vue {
  @Prop() event!: ReservationEvent
  @Prop() waiters!: WaitersQuery['waiters']

  refundState = {
    amount: 0,
    cancel: true,
    pin: null
  }

  refunding = false

  @Watch('event')
  onEventChange () {
    console.log(this.event)
  }

  get waiterOptions () {
    return this.waiters.map((waiter) => {
      return {
        value: waiter.id,
        label: `${waiter.firstname} ${waiter.lastname}`
      }
    })
  }

  get fields () {
    return [
      { key: 'description', label: 'description' },
      { key: 'baseAmount', label: 'Base' },
      { key: 'taxAmount', label: 'Tax' },
      { key: 'discountAmount', label: 'Discount' },
      { key: 'total', label: 'Total' },
      { key: 'refundedDate', label: 'refunded' }
    ]
  }

  get lineItems () {
    if (!this.reservation) return []
    return this.reservation.reservationLineItems.sort((a, b) => { return a.refunded === b.refunded ? 0 : a.refunded ? 1 : -1 })
  }

  get reservation () {
    if (!this.event) return
    return this.event.extendedProps.reservation
  }

  get extras () {
    if (!this.reservation) return []
    return this.reservation.reservationExtras
  }

  get rooms () {
    if (!this.reservation) return []
    return groupBy(this.reservation.reservationTimeSlots, (slot: ReservationTimeSlot) => slot.room.title)
  }

  get hasNotes (): boolean {
    return !!(this.reservation && this.reservation.notes !== '')
  }

  async close () {
    if (this.reservation && this.reservation.adminNotes) {
      await sdk.setAdminNote({ id: this.reservation.id, note: this.reservation.adminNotes })
    }
    this.$emit('reload')
    this.$bvModal.hide('viewReservation')
  }

  async rebook () {
    if (!this.reservation) return
    this.$router.push({ name: 'rebook_reservation', params: { id: `${this.reservation.id}` } })
  }

  get waiter () {
    if (!this.reservation || !this.reservation.waiter) return
    const currentId = this.reservation.waiter.id
    const waiter = this.waiters.find((waiter) => waiter.id === currentId)
    if (!waiter) return
    return {
      value: waiter.id,
      label: `${waiter.firstname} ${waiter.lastname}`
    }
  }

  async setWaiter (event) {
    if (!this.reservation) return
    if (!event) {
      await sdk.setWaiter({ reservation: this.reservation.id })
      this.reservation.waiter = null
    } else {
      await sdk.setWaiter({ reservation: this.reservation.id, id: event.value })
    }
    this.$emit('reload')
  }

  get isUser () {
    return this.$currentUser.role === Role.User
  }

  async refund () {
    if (!this.reservation) return
    this.refunding = true
    const payload: ReservationRefundMutationVariables = { id: this.reservation?.id, amount: this.refundState.amount * 100, cancel: this.refundState.cancel }
    if (this.refundState.pin) payload.pin = Number(this.refundState.pin)
    const response = await sdk.reservationRefund(payload)
    this.refunding = false
    if (response.reservationRefund) {
      this.$bvModal.hide('refundReservation')
      this.$emit('reload')
    } else {
      alert('something went wrong')
    }
  }

  async showCancelModal () {
    if (!this.reservation) return
    this.refundState.amount = this.reservation.refundableAmount / 100
    this.$bvModal.show('refundReservation')
  }

  time (date: Date) {
    return format(date, 'hh:mm a')
  }
}
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.paragraph {
  margin-bottom: 10px;
}
</style>
