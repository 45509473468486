<template lang="pug">
  .rooms
    .breadcrumbs
      h2 Create a Gift Card
    GiftcardForm(:saving='saving' @save='save' :error='error' @resetError='error=null')
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import createGiftcardGQL from '@/graphql/mutations/giftcard_create.graphql'
import Client from '@/graphql/client'
import {
  GiftcardCreateMutation,
  GiftcardCreateMutationVariables
} from '@/generated/graphql'
import GiftcardForm from './Form.vue'
import { Maybe } from 'graphql/jsutils/Maybe'
@Component({ components: { GiftcardForm } })
export default class CreateGiftcard extends Vue {
  saving = false
  error: Maybe<string> = null

  async save (payload) {
    this.saving = true
    try {
      await Client.query<GiftcardCreateMutation, GiftcardCreateMutationVariables>(
        createGiftcardGQL,
        {
          input: payload.form,
          pin: parseInt(payload.pin)
        }
      )
      this.$router.push({ name: 'giftcards' })
    } catch (e: any) {
      if (e.message.includes('Not Authorized')) {
        this.error = e.message
      }
    }
    this.saving = false
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 2rem;
}
.actions {
  margin-bottom: 20px;
  text-align: right;
}
</style>
