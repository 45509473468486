<template lang="pug">
b-modal(id='viewSlot' title='Manual Booking' ref='modal' @ok='book' @show='reset')
  .content
    b-form
      b-form-group(label='Client Name')
        b-form-input(v-model='form.name' :state='validateState("name")')
        b-form-invalid-feedback This field is required
      b-form-group(label='Email')
        b-form-input(type='email' v-model='form.email' :state='validateState("email")')
        b-form-invalid-feedback(v-if='!$v.form.email.required') This field is required
        b-form-invalid-feedback(v-if='!$v.form.email.email') This value is not a valid email
      b-form-group(label='Notes')
        b-form-textarea(v-model='form.notes')
  template(v-slot:modal-ok)
    b-spinner(small v-if='submitting').mr-2
    span Mark as booked
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ReservationsQuery, ReservationCreateMutation, ReservationCreateMutationVariables, ReservationStatus } from '@/generated/graphql'
import ReservationCreateGQL from '@/graphql/mutations/create_reservation.graphql'
import client from '@/graphql/client'
import { required, email } from 'vuelidate/lib/validators'

@Component({ components: { } })
export default class SlotModal extends Vue {
  @Prop() slots!: ReservationsQuery['rooms'][0]['timeSlots']
  @Prop() totalCost!: number
  @Prop() date!: string

  submitting = false

  form = {
    name: '',
    email: '',
    notes: ''
  }

  validations () {
    return {
      form: {
        name: { required },
        email: { required, email }
      }
    }
  }

  validateState (name) {
    if (!this.$v.form[name]) return
    const $dirty = this.$v.form[name]!.$dirty
    const $error = this.$v.form[name]!.$error
    return $dirty ? !$error : null
  }

  reset () {
    this.$v.$reset()
    this.submitting = false
  }

  async book (event) {
    event.preventDefault()
    this.$v.$touch()
    if (this.$v.$invalid) return

    this.submitting = true
    await client.query<ReservationCreateMutation, ReservationCreateMutationVariables>(ReservationCreateGQL, {
      data: {
        date: this.date,
        status: ReservationStatus.Manual,
        stripeReference: 'manual',
        orderTotal: 0,
        notes: this.form.notes,
        client: {
          name: this.form.name,
          email: this.form.email
        },
        reservationTimeSlots: this.slots.map((slot) => {
          return {
            date: this.date,
            startTime: slot.startTime,
            endTime: slot.endTime,
            price: slot.price,
            roomId: slot.room.id
          }
        })

      }
    })
    this.form = {
      name: '',
      email: '',
      notes: ''
    }
    this.$emit('reload')
  }
}
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
</style>
