<template lang="pug">
.rooms
  .breadcrumbs
    h2 Manage Waiters
  .table
    b-card.adminCard
      .actions
        b-button(size='sm' variant='primary' :to="{name: 'createWaiter'}")
          b-icon-plus-square-fill.mr-2
          span Create
      b-table(striped hover fixed :fields='fields' :items="waiters" :busy="loading")
        template(v-slot:table-busy)
          .text-center.text-danger.my-2
            b-spinner.align-middle
              strong Loading...
        template(v-slot:cell(actions)="data")
          router-link(:to="{name: 'editWaiter', params: {id: data.item.id}}" title='Edit')
            b-icon-pencil.mr-2
          a.link(@click='deleteWaiter(data.item.id)')
            b-icon-trash
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import waitersGQL from '@/graphql/queries/waiters.graphql'
import DeleteGQL from '@/graphql/mutations/delete_waiter.graphql'
import Client from '@/graphql/client'
import swal from 'sweetalert2'
import {
  DeleteWaiterMutation,
  DeleteWaiterMutationVariables,
  WaitersQuery,
  WaitersQueryVariables
} from '@/generated/graphql'
@Component({ components: {} })
export default class Rooms extends Vue {
  waiters: WaitersQuery['waiters'] = []
  fields = ['firstname', 'lastname', 'actions']

  loading = false
  mounted () {
    this.loadWaiters()
  }

  async loadWaiters () {
    this.loading = true
    const response = await Client.query<WaitersQuery, WaitersQueryVariables>(
      waitersGQL
    )
    this.waiters = response.waiters
    this.loading = false
  }

  async deleteWaiter (waiterId: number) {
    const confirm = await swal.fire({
      title: 'Are you sure?',
      confirmButtonText: 'Yes, delete it!',
      text: 'Once deleted, you will not be able to recover this extra',
      icon: 'warning',
      showCancelButton: true
    })
    if (confirm.isDismissed) { return }
    this.loading = true
    await Client.query<DeleteWaiterMutation, DeleteWaiterMutationVariables>(
      DeleteGQL,
      { id: waiterId }
    )
    await this.loadWaiters()
  }

  @Watch('filters', { deep: true })
  onFilterChange () {
    this.loadWaiters()
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 2rem;
}
.actions {
  margin-bottom: 20px;
  text-align: right;
}
.link {
  cursor: pointer;
  color: #007bff;
  &:hover {
    color: #0056b3;
  }
}

.avatar {
  max-width: 100px;
}
</style>
