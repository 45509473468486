<template lang="pug">
.rooms
  .breadcrumbs
    h2 Create an admin
  AdminForm(:saving='saving' @save='save')
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { sdk } from '@/graphql/client'
import AdminForm from './Form.vue'
import { CreateAdminMutationVariables } from '@/generated/graphql'
@Component({ components: { AdminForm } })
export default class CreateAdmin extends Vue {
  saving = false

  async save (payload: CreateAdminMutationVariables['admin']) {
    this.saving = true
    await sdk.CreateAdmin({ admin: payload })
    this.saving = false
    this.$router.push({ name: 'admins' })
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 2rem;
}
.actions {
  margin-bottom: 20px;
  text-align: right;
}
</style>
