<template lang="pug">
  .rooms
    .breadcrumbs
      h2 Create a Room
    RoomForm(:saving='saving' @save='save')
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import createRoomGQL from '@/graphql/mutations/create_room.graphql'
import Client from '@/graphql/client'
import {
  CreateRoomMutation,
  CreateRoomMutationVariables
} from '@/generated/graphql'
import RoomForm from './Form.vue'
@Component({ components: { RoomForm } })
export default class CreateRoom extends Vue {
  saving = false
  async save (payload) {
    this.saving = true
    const room = await Client.query<
      CreateRoomMutation,
      CreateRoomMutationVariables
    >(createRoomGQL, {
      room: payload
    })
    this.saving = false
    this.$router.push({
      name: 'roomSlots',
      params: { id: room.roomCreate!.id.toString() }
    })
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 2rem;
}
.actions {
  margin-bottom: 20px;
  text-align: right;
}
</style>

<style lang="scss">
.filepond--item {
  width: 50%;
}
</style>
