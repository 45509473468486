<template lang="pug">
.rooms
  .breadcrumbs
    h2 Create a Waiter
  WaiterForm(:saving='saving' @save='save')
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import createGQL from '@/graphql/mutations/create_waiter.graphql'
import Client from '@/graphql/client'
import {
  CreateWaiterMutation,
  CreateWaiterMutationVariables
} from '@/generated/graphql'
import WaiterForm from './Form.vue'
@Component({ components: { WaiterForm } })
export default class CreateWaiter extends Vue {
  saving = false

  async save (payload: CreateWaiterMutationVariables['waiter']) {
    this.saving = true
    await Client.query<CreateWaiterMutation, CreateWaiterMutationVariables>(
      createGQL,
      {
        waiter: payload
      }
    )
    this.saving = false
    this.$router.push({ name: 'waiters' })
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 2rem;
}
.actions {
  margin-bottom: 20px;
  text-align: right;
}
</style>
