import Vue from 'vue'

import {
  BootstrapVue,
  BIcon,
  BIconClock,
  BIconCashStack,
  BIconTrash,
  BIconAsterisk,
  BIconPlus,
  BIconPlusSquare,
  BIconPlusSquareFill,
  BIconPencil,
  BIconChevronLeft,
  BIconChevronRight,
  BIconCaretDown,
  BIconCaretDownFill,
  BIconBagCheck,
  BIconPeopleFill,
  BIconArrowLeft,
  BIconEnvelope,
  BIconEnvelopeFill,
  BIconCreditCard,
  BIconGiftFill,
  BIconCalendar2PlusFill,
  BIconCalendarFill,
  BIconLockFill,
  BIconImages,
  BIconArrowClockwise,
  BIconArrowUp,
  BIconArrowDown,
  BIconMap
} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.component('BIcon', BIcon)
Vue.component('BIconClock', BIconClock)
Vue.component('BIconCashStack', BIconCashStack)
Vue.component('BIconAsterisk', BIconAsterisk)
Vue.component('BIconPlus', BIconPlus)
Vue.component('BIconTrash', BIconTrash)
Vue.component('BIconPencil', BIconPencil)
Vue.component('BIconPlusSquare', BIconPlusSquare)
Vue.component('BIconPlusSquareFill', BIconPlusSquareFill)
Vue.component('BIconChevronLeft', BIconChevronLeft)
Vue.component('BIconChevronRight', BIconChevronRight)
Vue.component('BIconCaretDown', BIconCaretDown)
Vue.component('BIconCaretDownFill', BIconCaretDownFill)
Vue.component('BIconBagCheck', BIconBagCheck)
Vue.component('BIconPeopleFill', BIconPeopleFill)
Vue.component('BIconArrowLeft', BIconArrowLeft)
Vue.component('BIconEnvelope', BIconEnvelope)
Vue.component('BIconEnvelopeFill', BIconEnvelopeFill)
Vue.component('BIconCreditCard', BIconCreditCard)
Vue.component('BIconGiftFill', BIconGiftFill)
Vue.component('BIconCalendar2PlusFill', BIconCalendar2PlusFill)
Vue.component('BIconCalendarFill', BIconCalendarFill)
Vue.component('BIconLockFill', BIconLockFill)
Vue.component('BIconImages', BIconImages)
Vue.component('BIconArrowClockwise', BIconArrowClockwise)
Vue.component('BIconArrowUp', BIconArrowUp)
Vue.component('BIconArrowDown', BIconArrowDown)
Vue.component('BIconMap', BIconMap)
