<template lang="pug">
  .rooms
    .breadcrumbs
      h2 Manage Rooms
    .table
      b-card.adminCard
        .actions
          b-button(size='sm' variant='primary' :to="{name: 'createRoom'}")
            b-icon-plus-square-fill.mr-2
            span Create
        b-table(striped hover fixed :fields='fields' :items="rooms" :busy="loading" show-empty)
          template(#empty="scope")
            h5.text-center No rooms yet
          template(v-slot:table-busy)
            .text-center.text-danger.my-2
              b-spinner.align-middle
                strong Loading...
          template(v-slot:cell(image)="data")
            b-img.avatar(:src='imageUrl(data.item)' fluid)
          template(v-slot:cell(status)="data")
            b-badge {{ data.item.status }}
          template(v-slot:cell(position)="data")
            b-input.position(v-model='data.item.position' @update='move(data.item.id, $event)')
          template(v-slot:cell(actions)="data")
            router-link(:to="{name: 'roomSlots', params: {id: data.item.id}}")
              b-icon-calendar2-plus-fill.mr-2
            router-link(:to="{name: 'editRoom', params: {id: data.item.id}}")
              b-icon-pencil.mr-2
            b-icon-trash.link(@click='deleteRoom(data.item.id)')
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import roomsGQL from '@/graphql/queries/rooms.graphql'
import DeleteRoomGQL from '@/graphql/mutations/delete_room.graphql'
import Client, { sdk } from '@/graphql/client'
import swal from 'sweetalert2'
import {
  RoomsQuery,
  RoomsQueryVariables,
  DeleteRoomMutation,
  DeleteRoomMutationVariables
} from '@/generated/graphql'
@Component({ components: {} })
export default class Rooms extends Vue {
  rooms: RoomsQuery['rooms'] = []
  fields = ['image', 'title', 'people', 'status', 'position', 'actions']
  loading = false
  mounted () {
    this.loadRooms()
  }

  async loadRooms () {
    this.loading = true
    const response = await Client.query<RoomsQuery, RoomsQueryVariables>(
      roomsGQL
    )
    this.rooms = response.rooms
    this.loading = false
  }

  imageUrl (room: NonNullable<RoomsQuery['rooms']>[0]) {
    const image = room.images[0]
    if (!image) return null
    return image.variants?.thumb || image.path
  }

  move (id, position) {
    position = Number(position)
    sdk.moveRoom({ id, position })
  }

  async deleteRoom (id) {
    const confirm = await swal.fire({
      title: 'Are you sure?',
      confirmButtonText: 'Yes, delete it!',
      showCancelButton: true,
      text:
          "Once deleted, you will not be able to recover this room or it's timeslots!",
      icon: 'warning'
    })
    if (confirm.isDismissed) { return }
    this.loading = true
    await Client.query<DeleteRoomMutation, DeleteRoomMutationVariables>(
      DeleteRoomGQL,
      { room: id }
    )
    await this.loadRooms()
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 2rem;
}
.actions {
  margin-bottom: 20px;
  text-align: right;
}
.link {
  cursor: pointer;
  color: #007bff;
  &:hover {
    color: #0056b3;
  }
}

.avatar {
  max-width: 100px;
}

.position {
  width: 60px;
}
</style>
