import { render, staticRenderFns } from "./Admins.vue?vue&type=template&id=95cd4d76&scoped=true&lang=pug"
import script from "./Admins.vue?vue&type=script&lang=ts"
export * from "./Admins.vue?vue&type=script&lang=ts"
import style0 from "./Admins.vue?vue&type=style&index=0&id=95cd4d76&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95cd4d76",
  null
  
)

export default component.exports