<template lang="pug">
  .rooms(v-if='promotion')
    .breadcrumbs
      h2 Edit
    PromotionForm(:saving='saving' :promotion='promotion' @save='save')
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { sdk } from '@/graphql/client'
import swal from 'sweetalert2'
import {
  Maybe,
  PromotionQuery
} from '@/generated/graphql'
import PromotionForm from './Form.vue'
@Component({ components: { PromotionForm } })
export default class EditPromotion extends Vue {
  saving = false
  promotion: Maybe<PromotionQuery['promotion']> = null

  @Prop({ required: true }) id!: string

  async mounted () {
    this.promotion = (await sdk.Promotion({ id: parseInt(this.id) })).promotion
  }

  async save (payload) {
    this.saving = true
    await sdk.UpdatePromotion(
      { id: parseInt(this.id), promotion: payload }
    )
    this.saving = false
    swal.fire('Awesome!', 'promotion has been updated!', 'success')
    this.$router.push({
      name: 'promotions'
    })
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 2rem;
}
.actions {
  margin-bottom: 20px;
  text-align: right;
}
</style>
