<template lang="pug">
.rebook(v-if='reservation')
  b-modal(id='completed-msg' okOnly @ok="$router.push({name: 'reservations'})")
    | The reservation was successfully rebooked!
  h3 Rebook Reservation for:
  b-card.shadow.rebook-card(bg-variant="light")
    p
      strong.text-muted Original booked date: &nbsp;
      span {{formattedDate}}
    p
      strong.text-muted Client: &nbsp;
      span {{reservation.client.name}}
    p
      strong.text-muted Phone: &nbsp;
      span {{reservation.phoneNumber}}
    br
    p
      strong.text-muted Original booked room/s: &nbsp;
      div.border.p-2.mt-2.bg-white(v-for="slot in reservation.reservationTimeSlots")
        p
          | {{slot.room.title}}
          small.ml-2 original price: ${{slot.price}}
        p Slot: {{slot.startTime | timeFormat}} - {{slot.endTime | timeFormat}}

  hr
  Reservations(
    :isRebook='true'
    :date='currentDate'
    :editingId='id'
    :originalSlotCost='originalSlotCost'
    :originalBookedDate='formattedDate'
    @reload='onComplete')
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { sdk } from '@/graphql/client'
import Reservations from './Reservations.vue'
import { ReservationShowQuery } from '@/generated/graphql'
import { format } from 'date-fns'

@Component({ components: { Reservations } })
export default class Admins extends Vue {
  @Prop() date!: string
  @Prop() id

  loading = false
  reservation: ReservationShowQuery['reservation'] = null

  get originalSlotCost () {
    if (!this.reservation) return 0

    return this.reservation.reservationTimeSlots.reduce((sum: number, slot) => sum + slot.price, 0)
  }

  mounted () {
    this.fetch()
  }

  onComplete () {
    this.$bvModal.show('completed-msg')
  }

  async fetch () {
    this.loading = true
    this.reservation = (await sdk.ReservationShow({ id: parseInt(this.id) })).reservation
    this.loading = false
  }

  get formattedDate () {
    if (!this.reservation) return
    const date = new Date(+this.reservation.date)
    return date.toUTCString().split(' ').splice(0, 4).join(' ')
  }

  get bookedDate (): string {
    const date = new Date(this.formattedDate!)
    return format(date, 'yyyy-MM-dd')
  }

  get currentDate (): string {
    return this.date || this.bookedDate || format(new Date(), 'yyyy-MM-dd')
  }
}
</script>

<style lang="scss" scoped>
.rebook-card {
  max-width: 45rem;
  p {
    margin-bottom: 0;
  }
}
</style>
