<template lang="pug">
div
  b-navbar(type="dark" variant="dark")
    b-navbar-nav
      b-nav-item(:to='{name: "Home"}')
        b-icon-arrow-left
    b-navbar-brand.mx-auto
      .checkout-title
        b-icon-bag-check.mr-2
        span Booking Cancelation
  div(v-if='loaded')
    b-container
      b-row
        b-col
          b-card.mt-3
            b-card-body(v-if='cancelable')
              b-card-text
                p
                  span You are about to cancel your reservation for
                  | &nbsp;
                  strong {{ date }}.
                p
                  span If you proceed, this reservation will be lost and you will be refunded 97% of the original payment on your card.
              b-button(href="#" variant="danger" @click='cancel' :disabled='canceling')
                b-spinner.mr-2(small v-if='canceling')
                span Cancel
            b-card-body(v-else-if='notFound')
              p
                span Reservation Not Found
            b-card-body(v-else-if='!paid')
              p
                span This reservation was already refunded.
            b-card-body(v-else-if='late')
              p
                span We're sorry but this reservation is scheduled for less than 48 hours from now, according to our cancelation policy it is nonrefundable.

</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import reservationGQL from '@/graphql/queries/reservation.graphql'
import client from '@/graphql/client'
import { ReservationQuery, ReservationQueryVariables, ReservationStatus } from '@/generated/graphql'
import { Maybe } from 'graphql/jsutils/Maybe'
import { format, isAfter, addHours, parseISO } from 'date-fns'

@Component({ components: { } })
export default class Cancel extends Vue {
  @Prop() id!: string
  loaded = false
  canceling = false
  reservation: Maybe<ReservationQuery['ownReservation']> = null
  async mounted () {
    this.reservation = (await client.query<ReservationQuery, ReservationQueryVariables>(reservationGQL, { reference: this.id })).ownReservation
    this.loaded = true
    if (this.cancelable) {
      this.$router.push({ name: 'manage', params: { id: this.id } })
    }
  }

  get dateObject () {
    if (!this.reservation) return new Date()
    const firstSlot = this.reservation.reservationTimeSlots.sort((a, b) => {
      return Number(a.startTime.replace(':', '')) - Number(b.startTime.replace(':', ''))
    })[0]
    const dateWithTime = `${firstSlot.date.split('T')[0]}T${firstSlot.startTime}-0400`
    return parseISO(dateWithTime)
  }

  get date () {
    return format(this.dateObject, 'PPPP')
  }

  get notFound () {
    return this.loaded && !this.reservation
  }

  get paid () {
    return !this.notFound && this.reservation!.status === ReservationStatus.Paid
  }

  get cancelable () {
    return this.paid && isAfter(this.dateObject, addHours(new Date(), 48))
  }

  get late () {
    return this.paid && !this.cancelable
  }

  async cancel () {
    // if (!this.reservation) return
    // this.canceling = true
    // const response = (await client.query<UserCancelReservationMutation, UserCancelReservationMutationVariables>(cancelGQL, { reference: this.id })).userReservationCancel
    // if (response) {
    //   Swal.fire('Cancelation confirmed', `A refund of $${response.amount / 100} was issued on the card ending with ***${response.last4}`, 'success')
    //   this.$router.push({ name: 'Home' })
    // } else {
    //   Swal.fire('Error', 'There was an issue while canceling your reservation. Please try again later or contact us directly at (727) 329 9455', 'error')
    // }
    // this.canceling = false
  }
}
</script>

<style lang="scss" scoped>
</style>
