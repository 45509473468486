<template lang="pug">
  .rooms
    .breadcrumbs
      h2 Manage Promotions
    .table
      b-card.adminCard
        .actions
          b-button(size='sm' variant='primary' :to="{name: 'createPromotion'}")
            b-icon-plus-square-fill.mr-2
            span Create
        b-table(striped hover fixed :fields='fields' :items="promotions" :busy="loading" show-empty)
          template(#empty="scope")
            h5.text-center No promotions yet
          template(v-slot:table-busy)
            .text-center.text-danger.my-2
              b-spinner.align-middle
                strong Loading...
          template(v-slot:cell(dayOfWeek)="data")
            span {{ dayOfWeekToString(data.item.dayOfWeek) }}
          template(v-slot:cell(reduction)="data")
            span {{ data.item.reduction }}%
          template(v-slot:cell(actions)="data")
            router-link(:to="{name: 'editPromotion', params: {id: data.item.id}}")
              b-icon-pencil.mr-2
            b-icon-trash.link(@click='deletePromotion(data.item.id)')
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { sdk } from '@/graphql/client'
import swal from 'sweetalert2'
import {
  PromotionsQuery
} from '@/generated/graphql'
@Component({ components: {} })
export default class Rooms extends Vue {
  promotions: PromotionsQuery['promotions'] = []
  fields = ['dayOfWeek', 'reduction', 'actions']
  loading = false
  mounted () {
    this.loadPromotions()
  }

  async loadPromotions () {
    this.loading = true
    const response = await sdk.Promotions()
    this.promotions = response.promotions
    this.loading = false
  }

  dayOfWeekToString (day: number): string {
    switch (day) {
      case 0:
        return 'Sunday'
      case 1:
        return 'Monday'
      case 2:
        return 'Tuesday'
      case 3:
        return 'Wednesday'
      case 4:
        return 'Thursday'
      case 5:
        return 'Friday'
      case 6:
        return 'Saturday'
    }
    return 'Unknown'
  }

  async deletePromotion (id: number) {
    const confirm = await swal.fire({
      title: 'Are you sure?',
      confirmButtonText: 'Yes, delete it!',
      showCancelButton: true,
      text:
          'Once deleted, you will not be able to recover this promotion',
      icon: 'warning'
    })
    if (confirm.isDismissed) { return }
    this.loading = true
    await sdk.DeletePromotion({ promotion: id })
    await this.loadPromotions()
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 2rem;
}
.actions {
  margin-bottom: 20px;
  text-align: right;
}
.link {
  cursor: pointer;
  color: #007bff;
  &:hover {
    color: #0056b3;
  }
}

.avatar {
  max-width: 100px;
}

.position {
  width: 60px;
}
</style>
