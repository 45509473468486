<template lang="pug">
b-card.adminCard
  b-form
    b-form-group(label='Day of the week')
      b-form-select(v-model='form.dayOfWeek' :state="validateState('dayOfWeek')" :options='options')
      b-form-invalid-feedback This field is required
    b-form-group(label='Reduction')
      b-form-input(v-model='form.reduction' :state="validateState('reduction')")
      b-form-invalid-feedback This field is required
    b-form-group
      b-button(@click='submit' :disabled='processing')
        b-spinner(small v-if='processing').mr-2
        span Save
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { required } from 'vuelidate/lib/validators'
import { Maybe, PromotionQuery } from '@/generated/graphql'

@Component({ components: {} })
export default class Form extends Vue {
  process = false

  @Prop() promotion: Maybe<PromotionQuery['promotion']>
  @Prop() saving

  get processing () {
    return this.saving
  }

  get options () {
    return [
      { value: 0, text: 'Sunday' },
      { value: 1, text: 'Monday' },
      { value: 2, text: 'Tuesday' },
      { value: 3, text: 'Wednesday' },
      { value: 4, text: 'Thursday' },
      { value: 5, text: 'Friday' },
      { value: 6, text: 'Saturday' }
    ]
  }

  form = {
    dayOfWeek: 0,
    reduction: 10
  } as { dayOfWeek: number | string, reduction: number | string}

  validations () {
    return {
      form: {
        dayOfWeek: { required },
        reduction: { required }
      }
    }
  }

  validateState (name) {
    const $dirty = this.$v.form[name]?.$dirty
    const $error = this.$v.form[name]?.$error
    return $dirty ? !$error : null
  }

  created () {
    if (this.promotion) {
      this.form.dayOfWeek = this.promotion.dayOfWeek
      this.form.reduction = this.promotion.reduction
    }
  }

  async submit () {
    this.process = true
    this.$v.form.$touch()
    if (this.$v.form.$anyError) return
    this.form.dayOfWeek = Number(this.form.dayOfWeek)
    this.form.reduction = Number(this.form.reduction)
    this.$emit('save', this.form)
    this.process = false
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 2rem;
}
.actions {
  margin-bottom: 20px;
  text-align: right;
}
</style>
