<template lang="pug">
b-card.adminCard
  b-form
    b-form-group(label='First Name')
      b-form-input(v-model='form.firstname' :state="validateState('firstname')")
      b-form-invalid-feedback This field must be a valid email
    b-form-group(label='Last Name')
      b-form-input(v-model='form.lastname' :state="validateState('lastname')")
      b-form-invalid-feedback This field is required
    b-form-group
      b-button(@click='submit' :disabled='processing')
        b-spinner(small v-if='processing').mr-2
        span Save
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { required } from 'vuelidate/lib/validators'
import { Maybe, WaiterQuery, WaiterInput } from '@/generated/graphql'
@Component({})
export default class Form extends Vue {
  @Prop() waiter: Maybe<WaiterQuery['waiter']>
  @Prop() saving

  get processing () {
    return this.saving
  }

  form = {
    firstname: '',
    lastname: ''
  } as WaiterInput

  validations () {
    return {
      form: {
        firstname: { required },
        lastname: { required }
      }
    }
  }

  validateState (name) {
    const $dirty = this.$v.form[name]?.$dirty
    const $error = this.$v.form[name]?.$error
    return $dirty ? !$error : null
  }

  created () {
    if (this.waiter) {
      this.form.firstname = this.waiter.firstname
      this.form.lastname = this.waiter.lastname
    }
  }

  async submit () {
    this.$v.form.$touch()
    if (this.$v.form.$anyError) return

    this.$emit('save', this.form)
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 2rem;
}
.actions {
  margin-bottom: 20px;
  text-align: right;
}
</style>
