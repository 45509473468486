<template lang="pug">
  b-card.adminCard
    b-form
      b-form-group(label='Recipient Email')
        b-form-input(v-model='form.recipient' :state="validateState('recipient')")
        b-form-invalid-feedback This field must be a valid email
      b-form-group(label='Message')
        b-form-textarea(v-model='form.message' :state="validateState('message')")
      b-form-group(label='Sender Name')
        b-form-input(v-model='form.senderName' :state="validateState('senderName')")
        b-form-invalid-feedback This field is required
      b-form-group(label='Amount')
        b-form-input(v-model='form.amount' type='number' :min='0' :max='1000' :state="validateState('amount')" :formatter='amountFormatter')
        b-form-invalid-feedback This field must be a number between 1 to 1000
      b-form-group(label='Left' v-if='giftcard')
        b-form-input(:value='left' :state="validateState('left')" :disabled='true')
        b-form-invalid-feedback This field is required
      b-form-group(label='code' v-if='giftcard')
        b-form-input(v-model='form.code' :state="validateState('code')" :disabled='true')
        b-form-invalid-feedback This field is required
      b-form-group(label='Manager PIN' v-if='isUser')
        b-form-input(v-model='pin' @input="$emit('resetError')" :state="!error" type='password')
        b-form-invalid-feedback Pin is invalid
      b-form-group
        b-button(@click='submit' :disabled='processing')
          b-spinner(small v-if='processing').mr-2
          span Save
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { required, email, numeric } from 'vuelidate/lib/validators'
import { Maybe, GiftcardShowQuery, GiftcardAdminInput, Role } from '@/generated/graphql'
@Component({})
export default class Form extends Vue {
  @Prop() giftcard: Maybe<GiftcardShowQuery['giftcard']>
  @Prop() saving
  @Prop() error

  get processing () {
    return this.saving
  }

  get isUser () {
    return this.$currentUser.role === Role.User
  }

  form = {
    recipient: '',
    message: '',
    amount: 100,
    code: '',
    senderName: ''
  } as GiftcardAdminInput

  pin = null

  validations () {
    if (this.giftcard) {
      return {
        form: {
          recipient: { required, email },
          message: { },
          amount: { required, numeric },
          code: { required },
          senderName: { required }
        }
      }
    } else {
      return {
        form: {
          recipient: { required, email },
          message: { },
          amount: { required, numeric },
          senderName: { required }
        }
      }
    }
  }

  validateState (name) {
    const $dirty = this.$v.form[name]?.$dirty
    const $error = this.$v.form[name]?.$error
    return $dirty ? !$error : null
  }

  created () {
    if (this.giftcard) {
      this.form.recipient = this.giftcard.recipient
      this.form.message = this.giftcard.message
      this.form.amount = this.giftcard.amount
      this.form.code = this.giftcard.code
      this.form.senderName = this.giftcard.senderName
    }
  }

  get left () {
    if (this.giftcard) {
      const diff = this.giftcard.amount - this.giftcard.left
      return this.form.amount - diff
    } else {
      return this.form.amount
    }
  }

  amountFormatter (val) {
    if (val === '') return this.form.amount
    if (Number(val) > 1000) return '1000'
    if (Number(val) < 1) return '1'

    return val
  }

  async submit () {
    this.$v.form.$touch()
    if (this.$v.form.$anyError) return

    const amount = parseInt(this.form.amount as any)
    this.$emit('save', { form: { ...this.form, amount }, pin: this.pin })
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 2rem;
}
.actions {
  margin-bottom: 20px;
  text-align: right;
}
</style>
