import { CurrentUserQuery, Maybe, Role } from '@/generated/graphql'
import { sdk } from '@/graphql/client'

export default class CurrentUser {
  user: CurrentUserQuery['currentUser']

  constructor () {
    this.load()
  }

  async load () {
    this.user ||= (await sdk.CurrentUser()).currentUser
  }

  clear () {
    this.user = null
  }

  get isAuthenticated (): boolean {
    return !!this.user
  }

  get role (): Maybe<Role> {
    if (!this.user) return null
    return this.user.role
  }
}
