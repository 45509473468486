<template lang="pug">
div
  b-navbar(type="dark" variant="dark")
    b-navbar-nav
      b-nav-item(:to='{name: "manage", params: {id: id}}')
        b-icon-arrow-left
    b-navbar-brand.mx-auto
      .checkout-title
        b-icon-bag-check.mr-2
        span Refund Your Reservation
  div(v-if='reservation')
    b-container
      b-row
        b-col
          b-card.mt-3(body-class='custom-card-body' footer-class='custom-card-footer' no-body)
            b-card-body
              b-card-title Booking Details
              b-card-text
                b-table(:items='items' :fields='fields').items
                  template(#cell(total)="data")
                    span {{ data.value / 100  | toCurrency}}
                  template(#cell(refundAmount)="data")
                    span(v-if='selected(data.item.id)') {{ data.item.total / 100 | toCurrency }}
                    span(v-else) {{ 0 | toCurrency }}
                  template(#cell(checkbox)="data")
                    b-checkbox(v-if='refundable(data.item)' v-model='checked' :value='data.item.id' :disabled='!selectable(data.item)' @change='validateState')
                  template(#custom-foot)
                    b-tr(v-if='originalGratuity > 0')
                      b-td Gratuity
                      b-td {{  originalGratuity / 100 | toCurrency }}
                      b-td {{  gratuityRefund / 100 | toCurrency }}
                      b-td
                    b-tr
                      b-td Booking Fee
                      b-td {{ bookingFee / 100 | toCurrency }}
                      b-td Non-Refundable
                      b-td
                    b-tr
                      b-td
                        strong Total
                      b-td
                        strong {{ totalPaid / 100 | toCurrency }}
                      b-td
                        strong {{  amountToRefund / 100 | toCurrency }}
                      b-td
            b-card-footer.custom-card-footer
              b-button(@click='refund' :disabled='!submitable').green
                b-spinner(small v-if='canceling').mr-2
                span Refund {{ amountToRefund / 100 | toCurrency }}
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import reservationGQL from '@/graphql/queries/reservation.graphql'
import client, { sdk } from '@/graphql/client'
import { LineItemCategory, ReservationQuery, ReservationQueryVariables, ReservationStatus } from '@/generated/graphql'
import { Maybe } from 'graphql/jsutils/Maybe'
import { format, isAfter, addHours, parseISO } from 'date-fns'
import Swal from 'sweetalert2'

@Component({ components: { } })
export default class Cancel extends Vue {
  @Prop() id!: string
  loaded = false
  canceling = false

  checked: number[] = []

  reservation: Maybe<ReservationQuery['ownReservation']> = null

  fields = [
    {
      key: 'description'
    },
    {
      key: 'total',
      label: 'Paid'
    },
    {
      key: 'refundAmount',
      label: 'Refund'
    },
    {
      key: 'checkbox',
      label: 'Refund?'
    }
  ]

  get items () {
    if (!this.reservation) return []
    return this.reservation.reservationLineItems.filter((item) => this.refundable(item))
  }

  get submitable () {
    return this.checked.length > 0 && !this.canceling
  }

  get totalPaid () {
    if (!this.reservation) return 0
    return this.reservation.reservationLineItems.filter((item) => !item.refunded).map((item) => item.total).reduce((a, b) => a + b, 0)
  }

  validateState () {
    // if there is no remaining room, all extras must be selected
    if (this.remainingRooms.length === 0) {
      this.checked = this.checked.concat(this.remainingExtras.map((item) => item.id))
    }
  }

  refundable (item: NonNullable<ReservationQuery['ownReservation']>['reservationLineItems'][0]) {
    if (item.refunded) return false
    if (item.category === LineItemCategory.Fee) return false
    if (item.category === LineItemCategory.Gratuity) return false
    return true
  }

  selectable (item: NonNullable<ReservationQuery['ownReservation']>['reservationLineItems'][0]) {
    if (item.category === LineItemCategory.Room) return true
    if (item.category === LineItemCategory.Extra && this.remainingRooms.length > 0) return true
    return false
  }

  item (id: number): NonNullable<ReservationQuery['ownReservation']>['reservationLineItems'][0] | undefined {
    return this.reservation?.reservationLineItems.find((item) => item.id === id)
  }

  selected (id: number) {
    return this.checked.includes(id)
  }

  get bookingFee () {
    return this.reservation?.reservationLineItems.find((item) => item.category === LineItemCategory.Fee)?.total || 0
  }

  get selectedItems (): NonNullable<ReservationQuery['ownReservation']>['reservationLineItems'] {
    return this.checked.map((checked) => this.item(checked)!)
  }

  get originalGratuity () {
    if (!this.reservation) return 0
    return this.reservation.reservationLineItems.filter((item) => item.category === LineItemCategory.Gratuity).reduce((memo, item) => memo + item.total, 0)
  }

  get allExtras (): NonNullable<ReservationQuery['ownReservation']>['reservationLineItems'] {
    if (!this.reservation) return []
    return this.reservation.reservationLineItems.filter((item) => item.category === LineItemCategory.Extra && item.refunded === false)
  }

  get allRooms (): NonNullable<ReservationQuery['ownReservation']>['reservationLineItems'] {
    if (!this.reservation) return []
    return this.reservation.reservationLineItems.filter((item) => item.category === LineItemCategory.Room && item.refunded === false)
  }

  get remainingExtras (): NonNullable<ReservationQuery['ownReservation']>['reservationLineItems'] {
    return this.allExtras.filter((item) => !this.checked.includes(item.id))
  }

  get remainingRooms (): NonNullable<ReservationQuery['ownReservation']>['reservationLineItems'] {
    return this.allRooms.filter((item) => !this.checked.includes(item.id))
  }

  get selectedRooms (): NonNullable<ReservationQuery['ownReservation']>['reservationLineItems'] {
    if (!this.reservation) return []
    return this.selectedItems.filter((item) => item.category === LineItemCategory.Room)
  }

  get newGratuity () {
    return this.remainingExtras.filter((extra) => extra.includeInGratuity).reduce((memo, extra) => memo + extra.baseAmount, 0) * 18 / 100
  }

  get gratuityRefund () {
    return this.originalGratuity - this.newGratuity
  }

  get amountToRefund () {
    const items = this.selectedItems.map((item) => item.total).reduce((a, b) => a + b, 0)
    const gratuity = this.originalGratuity - this.newGratuity
    return items + gratuity
  }

  get allSelected (): boolean {
    if (!this.reservation) return false
    return this.selectedItems.length === this.reservation.reservationLineItems.filter((item) => this.refundable(item)).length
  }

  async mounted () {
    this.reservation = (await client.query<ReservationQuery, ReservationQueryVariables>(reservationGQL, { reference: this.id })).ownReservation
    if (this.reservation) {
      // this.checked = this.reservation.reservationLineItems.filter((item) => this.refundable(item)).map((item) => item.id)
      this.loaded = true
    }
  }

  get dateObject () {
    if (!this.reservation) return new Date()
    const firstSlot = this.reservation.reservationTimeSlots.sort((a, b) => {
      return Number(a.startTime.replace(':', '')) - Number(b.startTime.replace(':', ''))
    })[0]
    const dateWithTime = `${firstSlot.date.split('T')[0]}T${firstSlot.startTime}-0400`
    return parseISO(dateWithTime)
  }

  get date () {
    return format(this.dateObject, 'PPPPpp')
  }

  get notFound () {
    return this.loaded && !this.reservation
  }

  get paid () {
    return !this.notFound && this.reservation!.status === ReservationStatus.Paid
  }

  get cancelable () {
    return this.paid && (this.lastMinute || isAfter(this.dateObject, addHours(new Date(), 48)))
  }

  get late () {
    return this.paid && !this.cancelable
  }

  get lastMinute () {
    return this.$route.query.lastMinute === 'true'
  }

  async refund () {
    if (!this.reservation || this.canceling) return
    this.canceling = true
    const response = (await sdk.userReservationRefund({ reference: this.id, items: this.checked })).userReservationRefund
    if (response) {
      if (this.remainingRooms.length === 0) {
        Swal.fire('Booking Canceled', `A refund of ${this.$options?.filters?.toCurrency(response.amount / 100)} was issued on the card ending with ***${response.last4}`, 'success')
      } else {
        Swal.fire('Refund processed', `A refund of ${this.$options?.filters?.toCurrency(response.amount / 100)} was issued on the card ending with ***${response.last4}`, 'success')
      }
      this.$router.push({ name: 'Home' })
    } else {
      Swal.fire('Error', 'There was an issue while canceling your reservation. Please try again later or contact us directly at (727) 329 9455', 'error')
    }
    this.canceling = false
  }
}
</script>

<style lang="scss" scoped>
.details {
  margin-bottom: 15px;
}
.custom-card-body {
  padding: 0;
}
.custom-card-footer {
  background-color: #1d1d1d;
}
.items {
  font-size: 14px;
}
</style>
