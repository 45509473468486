<template lang="pug">
  .rooms
    .breadcrumbs
      h2 Manage Settings
    .table
      b-card.adminCard
        .actions
          b-button(size='sm' variant='primary' :to="{name: 'createSetting'}")
            b-icon-plus-square-fill.mr-2
            span Create
        b-table(striped hover fixed :fields='fields' :items="settings" :busy="loading" show-empty)
          template(#empty="scope")
            h5.text-center No settings yet
          template(v-slot:table-busy)
            .text-center.text-danger.my-2
              b-spinner.align-middle
                strong Loading...
          template(v-slot:cell(key)="data")
            span {{ key(data.item.key) }}
          template(v-slot:cell(actions)="data")
            router-link(:to="{name: 'editSetting', params: {settingKey: data.item.key}}")
              b-icon-pencil.mr-2
            b-icon-trash.link(@click='deleteSetting(data.item.id)')
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { sdk } from '@/graphql/client'
import swal from 'sweetalert2'
import {
  SettingKey,
  SettingsQuery
} from '@/generated/graphql'
@Component({ components: {} })
export default class Settings extends Vue {
  settings: SettingsQuery['settings'] = []
  fields = ['key', 'value', 'actions']
  loading = false
  mounted () {
    this.loadSettings()
  }

  async loadSettings () {
    this.loading = true
    const response = await sdk.Settings()
    this.settings = response.settings
    this.loading = false
  }

  key (key: SettingKey): string {
    switch (key) {
      case SettingKey.CtaText:
        return 'Prmotion Banner'
    }
  }

  async deleteSetting (id: number) {
    const confirm = await swal.fire({
      title: 'Are you sure?',
      confirmButtonText: 'Yes, delete it!',
      showCancelButton: true,
      text:
          'Once deleted, you will not be able to recover this setting',
      icon: 'warning'
    })
    if (confirm.isDismissed) { return }
    this.loading = true
    await sdk.DeleteSetting({ setting: id })
    await this.loadSettings()
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 2rem;
}
.actions {
  margin-bottom: 20px;
  text-align: right;
}
.link {
  cursor: pointer;
  color: #007bff;
  &:hover {
    color: #0056b3;
  }
}

.avatar {
  max-width: 100px;
}

.position {
  width: 60px;
}
</style>
