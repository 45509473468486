<template lang="pug">
b-card.adminCard
  b-form
    b-form-group(label='Setting Name')
      b-form-select(v-model='form.key' :state="validateState('key')" :options='options')
      b-form-invalid-feedback This field is required
    b-form-group(label='value')
      b-form-textarea(v-model='form.value' :state="validateState('value')")
      b-form-invalid-feedback This field is required
    b-form-group
      b-button(@click='submit' :disabled='processing')
        b-spinner(small v-if='processing').mr-2
        span Save
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { required } from 'vuelidate/lib/validators'
import { Maybe, SettingKey, SettingQuery } from '@/generated/graphql'

@Component({ components: {} })
export default class Form extends Vue {
  process = false

  @Prop() setting: Maybe<SettingQuery['setting']>
  @Prop() saving

  get processing () {
    return this.saving
  }

  get options () {
    return [
      { value: SettingKey.CtaText, text: 'Promotion Banner' }
    ]
  }

  form = {
    key: SettingKey.CtaText,
    value: ''
  } as { key: number | string, value: string}

  validations () {
    return {
      form: {
        key: { required },
        value: { required }
      }
    }
  }

  validateState (name) {
    const $dirty = this.$v.form[name]?.$dirty
    const $error = this.$v.form[name]?.$error
    return $dirty ? !$error : null
  }

  created () {
    if (this.setting) {
      this.form.key = this.setting.key
      this.form.value = this.setting.value
    }
  }

  async submit () {
    this.process = true
    this.$v.form.$touch()
    if (this.$v.form.$anyError) return
    this.$emit('save', this.form)
    this.process = false
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 2rem;
}
.actions {
  margin-bottom: 20px;
  text-align: right;
}
</style>
