<template lang="pug">
b-modal(id='viewRebookSlot' title='Rebook Reservation' ref='modal' @ok='rebook')
  b-alert(variant='danger' v-if='errorMsg') {{errorMsg}}
  | Are you sure you want to rebook?
  p(v-if='hasPriceDifference')
    p.text-danger {{costDifferenceText}}
    p A note will be made of this in the reservation notes for reference
  template(v-slot:modal-ok)
    b-spinner(small v-if='submitting').mr-2
    span Rebook
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ReservationsQuery, Role, ReservationRebookMutationVariables } from '@/generated/graphql'
import { sdk } from '@/graphql/client'

@Component({ components: { } })
export default class SlotRebookModal extends Vue {
  @Prop() slots!: ReservationsQuery['rooms'][0]['timeSlots']
  @Prop() cost!: number
  @Prop() originalSlotCost!: number
  @Prop() date!: string
  @Prop() id!: string
  @Prop() originalBookedDate

  submitting = false
  errorMsg: string | null = null

  get hasPriceDifference () {
    return Number(this.originalSlotCost) !== Number(this.cost)
  }

  get costDifferenceText () {
    const difference = this.originalSlotCost - this.cost
    if (difference < 0) return `There is an additional cost of $${Math.abs(difference)} that should be charged to the customer`
    if (difference === 0) return ''
    return `There is a refund difference of $${difference} that should be returned to the customer.`
  }

  get adminNotes () {
    const note = `Rebooking Note: This reservation was rebooked from ${this.originalBookedDate} to ${this.formatDateToSting()}.`
    const cost = this.hasPriceDifference ? `\n${this.costDifferenceText}` : ''
    return note + cost
  }

  get isUser () {
    return this.$currentUser.role === Role.User
  }

  formatDateToSting () {
    const date = new Date(this.date)
    return date.toUTCString().split(' ').splice(0, 4).join(' ')
  }

  async rebook (event) {
    event.preventDefault()

    this.submitting = true
    const timeslots = this.slots.map((slot) => {
      return {
        date: this.date,
        startTime: slot.startTime,
        endTime: slot.endTime,
        price: slot.price,
        roomId: slot.room.id
      }
    })
    try {
      const params = { id: parseInt(this.id), date: this.date, timeslots, adminNotes: this.adminNotes } as ReservationRebookMutationVariables
      await sdk.ReservationRebook(params)
      this.$emit('reload')
    } catch (e: any) {
      console.error(e)
      this.errorMsg = 'an unknown error occurred.'
    }
  }
}
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
</style>
