import { GraphQLClient } from 'graphql-request'
import { RequestDocument } from 'graphql-request/dist/types'
import { getSdk } from '@/generated/graphql'
class Client {
  client: GraphQLClient
  constructor () {
    this.client = new GraphQLClient(process.env.VUE_APP_API_URL || 'http://localhost:4000/graphql', { credentials: 'include' })
  }

  async query<T, V> (
    gql: RequestDocument,
    variables: V | undefined = undefined
  ): Promise<T> {
    return await this.client.request(gql, variables)
  }
}

const client = new Client()

export default client

const sdkRequester = async function<T, V> (gql: RequestDocument, variables: V | undefined = undefined): Promise<T> {
  return await client.client.request(gql, variables)
}

export const sdk = getSdk(sdkRequester)
