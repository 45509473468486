<template lang="pug">
b-card.adminCard
  b-form
    b-form-group(label='Title')
      b-form-input(v-model='form.title' :state="validateState('title')")
      b-form-invalid-feedback This field is required
    b-form-group(label='Description')
      b-form-textarea(v-model='form.description' :state="validateState('description')")
      b-form-invalid-feedback This field is required
    b-form-group(label='Price')
      number-input(controls center rounded v-model='form.price')
    b-form-group(label='Value')
      number-input(controls center rounded v-model='form.value')
    b-form-group(label='Included in Gratuity')
      b-form-checkbox(v-model='form.includeInGratuity')
    b-form-group(label='Type')
      b-form-select(v-model='form.type')
        option(value='FOOD') Food
        option(value='ALCOHOL') Alcoholic
        option(value='NONALCOHOL') Non-Alcoholic
        option(value='GOODIES') Goodies
    b-form-group
      ImageUpload(:images='images' @update:count='imageCount=$event' ref='upload')
      b-form-invalid-feedback(:state='imageCount !== 0') Please add an image.
    b-form-group
      b-button(@click='save' :disabled='saving')
        b-spinner(small v-if='saving').mr-2
        span Save
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { required } from 'vuelidate/lib/validators'
import { Maybe, ExtraInput, ExtraQuery, ExtraType } from '@/generated/graphql'
import ImageUpload from '@/components/ImageUpload.vue'
import { FilePondFile } from 'filepond'
@Component({ components: { ImageUpload } })
export default class Form extends Vue {
  imageCount = 0
  process = false
  images: string[] = []

  @Prop() extra: Maybe<ExtraQuery['extra']>
  @Prop() saving

  form = {
    title: '',
    description: '',
    price: 50,
    value: null,
    includeInGratuity: true,
    image: { path: '' },
    type: ExtraType.Alcohol
  } as ExtraInput

  validations () {
    return {
      form: {
        title: { required },
        description: { required },
        price: { required },
        image: {
          path: { required }
        }
      }
    }
  }

  validateState (name) {
    const $dirty = this.$v.form[name]?.$dirty
    const $error = this.$v.form[name]?.$error
    return $dirty ? !$error : null
  }

  created () {
    if (this.extra) {
      this.images = [this.extra.image!.path]
      this.form.title = this.extra.title
      this.form.description = this.extra.description || ''
      this.form.price = this.extra.price
      this.form.value = this.extra.value
      this.form.includeInGratuity = this.extra.includeInGratuity
      this.form.type = this.extra.type
    }
  }

  async save () {
    this.$v.form.$touch()

    const images = await (this.$refs.upload as ImageUpload).onProcess('doit')
    const path = images.files.length ? this.path(images.files[0]) : ''
    const variants = images.paths.find(i => i.original === path)
    this.form.image = { path, variants }

    if (this.$v.form.$anyError) {
      this.saving = false
      return
    }
    this.$emit('save', this.form)
    this.saving = false
  }

  path (file: FilePondFile) {
    return file.serverId.startsWith('http')
      ? file.serverId
      : `https://lalarooms.s3.amazonaws.com/${file.serverId}`
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 2rem;
}
.actions {
  margin-bottom: 20px;
  text-align: right;
}
</style>
