export default class Dialog {
  component: any = null

  constructor (component) {
    this.component = component
  }

  async confirm (msg: string, opts = {}): Promise<boolean> {
    return this.component.$bvModal.msgBoxConfirm(
      msg,
      Object.assign(
        {
          title: 'Are you sure?',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'success',
          okTitle: 'Ok',
          cancelTitle: 'Cancel',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        },
        opts
      )
    )
  }

  async alert (msg: string): Promise<boolean> {
    return this.component.$bvModal.msgBoxOk(msg, {
      title: 'Warning',
      hideHeaderClose: false,
      headerBgVariant: 'secondary',
      headerTextVariant: 'light',
      centered: true
    })
  }
}
